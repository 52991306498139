<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @submit="handleSubmit" :form="form">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="关键字">
              <a-input v-decorator="[
                'queryKeywords',
                {
                  initialValue: queryParam.keywords
                }
              ]" placeholder="ID/用户名/昵称/电话/备注" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="状态">
              <a-select placeholder="请选择" v-decorator="['queryStatus', { initialValue: queryParam.status }]">
                <a-select-option value="0">全部</a-select-option>
                <a-select-option value="1">运行中</a-select-option>
                <a-select-option value="-1">关闭</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button htmlType="submit" :loading="submitLoading" type="primary">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>
              <a-button style="float: right" @click="goAddAdmin">添加管理员</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <a-table :columns="columns" :dataSource="tableData" :loading="tableLoading" rowKey="id">
      <span slot="avatar" slot-scope="text">
        <img :src="text" height="25" style="border-radius: 50%;">
      </span>

      <span slot="status" slot-scope="text">
        {{ text | statusFilter }}
      </span>

      <span slot="last_login_time" slot-scope="text">
        {{ text | dayjs }}
      </span>

      <span slot="action" slot-scope="text, record">
        <a @click="goEditAdmin(record)">编辑</a>
        <a-divider type="vertical" />
        <a @click="showDelModal(record)">删除</a>
      </span>
    </a-table>

    <!-- 删除管理员对话框 -->
    <a-modal title="删除管理员" :visible="del_visible" @ok="handleDelOk" :confirmLoading="delConfirmLoading"
      @cancel="handleDelCancel">
      <p>{{ ModalText }}</p>
    </a-modal>

  </a-card>
</template>

<script>
import { getAdminList, adminDelete } from '@/api/admin'
import merge from 'webpack-merge'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '分组',
    dataIndex: 'admin_group.title'
  },
  {
    title: '头像',
    dataIndex: 'avatar',
    scopedSlots: { customRender: 'avatar' } // 自定义渲染方法
  },
  {
    title: '用户名',
    dataIndex: 'username'
  },
  {
    title: '昵称',
    dataIndex: 'nickname'
  },
  {
    title: '手机',
    dataIndex: 'phone'
  },
  {
    title: '最后登录时间',
    dataIndex: 'last_login_time',
    scopedSlots: { customRender: 'last_login_time' }
  },
  {
    title: '最后登录IP',
    dataIndex: 'last_login_ip'
  },
  {
    title: '累计登录',
    dataIndex: 'login_num'
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '操作',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }]

export default {
  name: 'AdminList',
  data() {
    return {
      description: '列表使用场景：后台管理中的权限管理以及角色管理，可用于基于 RBAC 设计的角色权限控制，颗粒度细到每一个操作类型。',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      // 高级搜索 展开/关闭
      advanced: false,
      // form
      form: this.$form.createForm(this),
      // 查询参数
      queryParam: {
        keywords: this.$route.query.keywords,
        status: (this.$route.query.status ? this.$route.query.status : '0')
      },
      columns,
      tableData: [],
      submitLoading: false,
      tableLoading: false,
      // 删除
      del_visible: false,
      ModalText: '确定删除？',
      delConfirmLoading: false,
      delMid: {}

    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        1: '正常',
        2: '禁用'
      }
      return statusMap[status]
    }
  },
  created() {
    const parameter = this.queryParam
    this.loadData(parameter)
  },
  methods: {
    loadData(parameter) {
      this.tableLoading = true
      new Promise((resolve, reject) => {
        getAdminList(parameter).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.tableData = res.data
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message,
          duration: 4
        })
      }).finally(() => {
        this.tableLoading = false
      })
    },
    goAddAdmin() {
      this.$router.push('/config/admin/edit')
    },
    goEditAdmin(record) {
      this.$router.push({ path: '/config/admin/edit', query: { id: record.id } })
    },
    showDelModal(record) {
      this.ModalText = `确定删除用户名为 ${record.username} 的管理员吗？`
      this.delMid = Object.assign({}, record)
      this.del_visible = true
    },
    handleDelOk() {
      const record = this.delMid
      this.delConfirmLoading = true
      const id = record.id
      const param = {}
      param.id = id
      new Promise((resolve, reject) => {
        adminDelete(param).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        // 删除成功
        this.$notification.success({
          message: res.status,
          description: res.message
        })
        this.loadData()
        setTimeout(() => {
          this.del_visible = false
          this.delMid = {}
        }, 2000)
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message
        })
      }).finally(() => {
        this.delConfirmLoading = false
      })
    },
    handleDelCancel() {
      this.delMid = {}
      this.del_visible = false
    },
    handleSubmit(e) {
      // 重置查询条件，并重新请求
      e.preventDefault()
      const parameter = {}
      parameter.keywords = this.form.getFieldValue('queryKeywords')
      parameter.status = this.form.getFieldValue('queryStatus')
      this.submitLoading = true
      this.loadData(parameter)
      this.submitLoading = false
      this.$router.push({
        query: merge(this.$route.query, parameter)
      })
    },
    resetQuery() {
      // 重置查询条件，并重新请求
      this.form.setFieldsValue({ 'queryStatus': '0', queryKeywords: '' })
      this.$router.push({
        query: merge({}, {})
      })
      this.submitLoading = true
      this.loadData()
      this.submitLoading = false
    }
  }
}
</script>
