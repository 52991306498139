import api from './index'
import { axios } from '@/utils/request'

// 此处封装的方法为返回一个异步请求对象
// 使用该接口时将该对象放入Promiss中进行处理

export function getInfo() {
    return axios({
        url: '/admin/account/info',
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 更新管理员账户信息
export function accountUpdate(parameter) {
    return axios({
        url: '/admin/account/update',
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取管理员列表
export function getAdminList(parameter) {
    return axios({
        url: api.AdminList,
        method: 'get',
        params: parameter
    })
}

// 获取单个管理员信息
export function getAdminInfo(parameter) {
    return axios({
        url: api.AdminInfo,
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 添加管理员
export function adminAdd(parameter) {
    return axios({
        url: api.AdminAdd,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 更新管理员信息
export function adminUpdate(parameter) {
    return axios({
        url: api.AdminUpdate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 删除管理员
export function adminDelete(parameter) {
    return axios({
        url: api.AdminDelete,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 管理组列表
export function adminGroupList() {
    return axios({
        url: '/admin/admin_group/getlist',
        method: 'get'
    })
}

// 删除管理组
export function adminGroupDelete(parameter) {
    return axios({
        url: '/admin/admin_group/delete',
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 获取一个管理组数据
export function getAdminGrupInfo(parameter) {
    return axios({
        url: '/admin/admin_group/read',
        method: 'get',
        params: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 添加管理组
export function adminGroupAdd(parameter) {
    return axios({
        url: api.AdminGroupAdd,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 修改管理组
export function adminGroupUpdate(parameter) {
    return axios({
        url: api.AdminGroupUpdate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 节点获取
export function nodeList() {
    return axios({
        url: api.NodeList,
        method: 'get'
    })
}

// 树节点获取
export function nodeTreeList() {
    return axios({
        url: api.NodeTreeList,
        method: 'get'
    })
}

// 新增节点
export function nodeAdd(parameter) {
    return axios({
        url: api.NodeAdd,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 更新节点
export function nodeUpdate(parameter) {
    return axios({
        url: api.NodeUpdate,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

// 删除节点
export function nodeDelete(parameter) {
    return axios({
        url: api.NodeDelete,
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
